import { ReactNode } from "react";

export type ActionRecordAudioType = {
  audio: HTMLAudioElement;
  blob: Blob;
  path: string;
};

export type BaseProviderType = {
  children: ReactNode;
};

export type BaseReponseType = {
  idReturnAPI: number;
  deReturnAPI: string;
  dtServer: string;
};

export enum ControlTypeEnum {
  Text = "AytyFramework.UI.ViewType.Controls.TextBox",
  MaskedText = "AytyFramework.UI.ViewType.Controls.MaskedTextBox",
  Button = "AytyFramework.UI.ViewType.Controls.Button",
  Select = "AytyFramework.UI.ViewType.Controls.ComboBoxDropDown",
}

export enum EnvironmentEnum {
  development = "development",
  test = "test",
  homolog = "homolog",
  production = "production",
}

export enum ReportEnum {
  call,
  detailedSales,
  hsmBatchDetail,
  interaction,
  interactionAndRecording,
  interactionMap,
  licenseAnalytic,
  licenseConsolidated,
  operationsPerformance,
  order,
  overview,
  ticketAnalytic,
  ticketConsolidated,
}

export type LocationStateType = {
  from: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
  };
};

export type ModalParamsType = {
  open: boolean;
  onClose: () => void;
};

export type OptionType<T> = {
  _id?: string;
  value: T;
  label: string;
};

export type PageXLSType = {
  name?: string;
  list: object[];
  head?: string[];
  footer?: string[];
};

export type PermissionProviderType = BaseProviderType & {
  hasPermission?: boolean;
};

export type RecordAudioType = {
  start: () => void;
  stop: () => Promise<ActionRecordAudioType>;
  finish: () => Promise<ActionRecordAudioType>;
};

export type StepItemType = {
  _id: string;
  title: string;
  children: JSX.Element;
  to: string;
};
