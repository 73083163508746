import { ReactComponent as PasswordIcon } from "assets/icons/key.svg";
import { ReactComponent as LeftArrowIcon } from "assets/icons/leftArrow.svg";
import { ReactComponent as Logo } from "assets/imgs/logo.svg";
import { ReactComponent as LogoIcon } from "assets/imgs/logoIcon.svg";
import { ReactComponent as AvatarIcon } from "assets/imgs/profile.svg";
import { ReactComponent as AdministrativeIcon } from "assets/sidebar/administrative.svg";
import { ReactComponent as AttendanceIcon } from "assets/sidebar/attendance.svg";
import { ReactComponent as AuditIcon } from "assets/sidebar/audit.svg";
import { ReactComponent as CompanyIcon } from "assets/sidebar/company.svg";
import { ReactComponent as DashboardIcon } from "assets/sidebar/dashboard.svg";
import { ReactComponent as DocumentationIcon } from "assets/sidebar/document.svg";
import { ReactComponent as ImportIcon } from "assets/sidebar/import.svg";
import { ReactComponent as NoteIcon } from "assets/sidebar/note.svg";
import { ReactComponent as PauseIcon } from "assets/sidebar/pause.svg";
import { ReactComponent as ProjectIcon } from "assets/sidebar/project.svg";
import { ReactComponent as ReportIcon } from "assets/sidebar/report.svg";
import { ReactComponent as RegisterIcon } from "assets/sidebar/setting.svg";
import { ReactComponent as SignOutIcon } from "assets/sidebar/signOut.svg";
import Divisor from "components/divisor";
import {
  documentationPath,
  environmentIsDevelopment,
  ls,
  menuKey,
} from "consts";
import {
  useAttendance,
  useAuth,
  useInterval,
  useProfile,
  useToast,
} from "providers";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { Errors } from "services/errors";
import * as UserService from "services/user";
import { aytyFormatError, formatAgentName } from "utils";
import NavDropdown from "../navDropdown";
import NavItem from "../navItem";
import { Nav, NavButton, ProfileAvatar, SideBarContainer } from "./styles";

type NavItemType = {
  icon: JSX.Element;
  label?: string;
  name: string;
  path?: string;
  hasPermission?: boolean;
  subItems?: {
    label: string;
    path?: string;
    icon?: JSX.Element;
    hasPermission?: boolean;
    action?: () => void;
  }[];
};

const initialItem = ls.getItem(menuKey);
const initialCollapse = initialItem ? JSON.parse(initialItem) : false;

const SideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapse);
  const { signOut, changePassword } = useAuth();
  const profile = useProfile();
  const { user, isSagoCore, selectedProject } = profile;
  const { deleteUser, handleOpenSelect, handleNotepad } = profile;
  const { inPause, handleOpenModal } = useInterval();
  const { hasAttendance } = useAttendance();
  const { t } = useTranslation("components");
  const { warning, error, removeFixed } = useToast();
  const navigate = useNavigate();

  const errorsResolver = useMemo(
    () => new Errors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasUser = useMemo(() => !!user, [user]);

  const goToAttendance = useCallback(() => {
    warning({ description: t("alerts.finishYourAttendances") });
    navigate("./attendance");
  }, [warning, t, navigate]);

  const goOut = useCallback(async () => {
    await UserService.signOut()
      .then(({ data }) => {
        if (data.idReturnAPI > 0) {
          removeFixed();
          deleteUser();
          signOut();
        } else warning({ description: t(aytyFormatError(data)) });
      })
      .catch(errorsResolver.defaultError);
  }, [errorsResolver, removeFixed, deleteUser, signOut, warning, t]);

  const handleSignOut = useCallback(async () => {
    if (hasAttendance && !environmentIsDevelopment) goToAttendance();
    else await goOut();
  }, [hasAttendance, goToAttendance, goOut]);

  const profileItems = useMemo(() => {
    if (isSagoCore)
      return new Array<NavItemType>({
        icon: <ProfileAvatar icon={<AvatarIcon />} />,
        label: t("sideBar.profile"),
        name: formatAgentName(user?.nmUser) || "...",
        subItems: [
          {
            label: t("changePassword"),
            icon: <PasswordIcon />,
            hasPermission: !user?.mustBlockChangePassword,
            action: changePassword,
          },
          {
            label: t("exit"),
            icon: <SignOutIcon />,
            action: handleSignOut,
          },
        ].filter(({ hasPermission }) => hasPermission ?? true),
      });

    return new Array<NavItemType>(
      {
        icon: <ProfileAvatar icon={<AvatarIcon />} $inPause={inPause} />,
        label: t("sideBar.profile"),
        name: formatAgentName(user?.nmUser) || "...",
        subItems: [
          {
            label: t("pause"),
            icon: <PauseIcon />,
            hasPermission: user?.hasAccessAttend,
            action: handleOpenModal,
          },
          {
            label: t("notepad"),
            icon: <NoteIcon />,
            action: handleNotepad,
          },
          {
            label: t("changePassword"),
            icon: <PasswordIcon />,
            hasPermission: !user?.mustBlockChangePassword,
            action: changePassword,
          },
          {
            label: t("exit"),
            icon: <SignOutIcon />,
            action: handleSignOut,
          },
        ].filter(({ hasPermission }) => hasPermission ?? true),
      },
      {
        icon: <CompanyIcon />,
        label: t("sideBar.company"),
        name: "Ligo",
        path: "company",
        hasPermission: environmentIsDevelopment && false, // ToDo: Remover quando página finalizada
      }
    ).filter(({ hasPermission }) => hasPermission ?? true);
  }, [
    user,
    isSagoCore,
    inPause,
    handleOpenModal,
    handleNotepad,
    changePassword,
    handleSignOut,
    t,
  ]);

  const navItems: NavItemType[] = useMemo(() => {
    if (isSagoCore)
      return new Array<NavItemType>(
        {
          icon: <DashboardIcon />,
          name: t("sideBar.dashboard.title"),
          hasPermission: user?.hasAccessDashboard,
          subItems: [
            {
              label: t("sideBar.dashboard.queue"),
              path: "./dashboard",
            },
          ],
        },
        {
          icon: <ReportIcon />,
          name: t("sideBar.report.title"),
          hasPermission: user?.hasAccessReport,
          subItems: [
            {
              label: t("sideBar.report.generator"),
              path: "./report/generator",
            },
          ],
        },
        {
          icon: <RegisterIcon />,
          name: t("sideBar.registration.title"),
          hasPermission: user?.hasAccessRegister,
          subItems: [
            {
              label: t("sideBar.registration.user"),
              path: "./registration",
            },
          ],
        },
        {
          icon: <AdministrativeIcon />,
          name: t("sideBar.administrative.title"),
          hasPermission: user?.hasAccessAdmin,
          subItems: [
            {
              label: t("sideBar.administrative.project"),
              path: "./administrative",
            },
          ],
        },
        {
          icon: <ImportIcon />,
          name: t("sideBar.import"),
          path: "import",
          hasPermission: environmentIsDevelopment,
        }
      ).filter(({ hasPermission }) => hasPermission ?? true);

    return new Array<NavItemType>(
      {
        icon: <AttendanceIcon />,
        name: t("sideBar.attendance"),
        path: "attendance",
        hasPermission: user?.hasAccessAttend,
      },
      {
        icon: <DashboardIcon />,
        name: t("sideBar.dashboard.title"),
        hasPermission: user?.hasAccessDashboard,
        subItems: [
          {
            label: t("sideBar.dashboard.queue"),
            path: "./dashboard",
          },
        ],
      },
      {
        icon: <AuditIcon />,
        name: t("sideBar.audit.title"),
        hasPermission: user?.hasAccessAudit && user?.hasOrderModule,
        subItems: [
          {
            label: t("sideBar.audit.order"),
            path: "./audit",
            hasPermission: user?.hasOrderModule,
          },
        ].filter(({ hasPermission }) => hasPermission ?? true),
      },
      {
        icon: <ReportIcon />,
        name: t("sideBar.report.title"),
        hasPermission: user?.hasAccessReport,
        subItems: [
          {
            label: t("sideBar.report.history"),
            path: "./report",
          },
          {
            label: t("sideBar.report.generator"),
            path: "./report/generator",
          },
        ],
      },
      {
        icon: <RegisterIcon />,
        name: t("sideBar.registration.title"),
        hasPermission: user?.hasAccessRegister,
        subItems: [
          {
            label: t("sideBar.registration.userQueue"),
            path: "./registration/userQueue",
          },
          {
            label: t("sideBar.registration.hsm"),
            path: "./registration/hsm",
          },
          {
            label: t("sideBar.registration.channel"),
            path: "./registration/channel",
          },
          {
            label: t("sideBar.registration.faq"),
            path: "./registration/faq",
          },
          {
            label: t("sideBar.registration.product"),
            path: "./registration/product",
          },
          {
            label: t("sideBar.registration.ticket"),
            path: "./registration/ticket",
            hasPermission: user?.hasTicketModule,
          },
          {
            label: t("sideBar.registration.attendance"),
            path: "./registration/attendance",
          },
        ].filter(({ hasPermission }) => hasPermission ?? true),
      },
      {
        icon: <AdministrativeIcon />,
        name: t("sideBar.administrative.title"),
        hasPermission: user?.hasAccessAdmin,
        subItems: [
          {
            label: t("sideBar.administrative.permission"),
            path: "./administrative/permission",
          },
          {
            label: t("sideBar.administrative.status"),
            path: "./administrative/status",
          },
          {
            label: t("sideBar.administrative.form"),
            path: "./administrative/form",
          },
          {
            label: t("sideBar.administrative.pause"),
            path: "./administrative/pause",
          },
          {
            label: t("sideBar.administrative.campaignType"),
            path: "./administrative/campaignType",
          },
        ],
      }
    ).filter(({ hasPermission }) => hasPermission ?? true);
  }, [user, isSagoCore, t]);

  const handleToggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    ls.setItem(menuKey, JSON.stringify(!isCollapsed));
  }, [isCollapsed]);

  return (
    <SideBarContainer $isCollapsed={isCollapsed}>
      <NavButton
        $isCollapsed={isCollapsed}
        icon={<LeftArrowIcon />}
        onClick={handleToggleCollapse}
      />
      <div>
        <NavLink to="/">
          {isCollapsed ? <LogoIcon width="100%" /> : <Logo />}
        </NavLink>
        <Nav>
          {profileItems?.map((item, index) =>
            item.subItems?.length ? (
              <NavDropdown
                key={index}
                text={item.name}
                icon={item.icon}
                label={item.label}
                $disable={!hasUser}
                items={item.subItems}
                $justIcon={isCollapsed}
              />
            ) : (
              <NavLink key={index} to={hasUser ? item.path! : ""}>
                {({ isActive }) => (
                  <NavItem
                    icon={item.icon}
                    name={item.name}
                    label={item.label}
                    $justIcon={isCollapsed}
                    $isActive={hasUser && isActive}
                  />
                )}
              </NavLink>
            )
          )}
          <NavItem
            icon={<ProjectIcon />}
            $justIcon={isCollapsed}
            onClick={handleOpenSelect}
            label={t("sideBar.project")}
            name={selectedProject?.DeProject ?? "..."}
          />
          <Divisor />
          {navItems?.map((item, index) =>
            item.subItems?.length ? (
              <NavDropdown
                key={index}
                text={item.name}
                icon={item.icon}
                $disable={!hasUser}
                items={item.subItems}
                $justIcon={isCollapsed}
              />
            ) : (
              <NavLink key={index} to={hasUser ? item.path! : ""}>
                {({ isActive }) => (
                  <NavItem
                    icon={item.icon}
                    name={item.name}
                    $justIcon={isCollapsed}
                    $isActive={hasUser && isActive}
                  />
                )}
              </NavLink>
            )
          )}
          <Divisor />
          <a href={documentationPath} target="_blank" rel="noreferrer">
            <NavItem
              icon={<DocumentationIcon />}
              name={t("sideBar.documentation")}
              $justIcon={isCollapsed}
            />
          </a>
        </Nav>
      </div>
    </SideBarContainer>
  );
};

export default SideBar;
