import { csvURI, textURI } from "consts";
import { PageXLSType } from "models";
import { utils, writeFileXLSX } from "xlsx";

export const downloadCSV = (csv: string, filename: string) => {
  downloadFile(
    `${csvURI}${encodeURIComponent(csv)}`,
    `${filename ?? "file"}.csv`
  );
};

export const downloadFile = (file: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.target = "_blank";
  link.href = file;
  link.click();
};

export const downloadHTML = (
  html: string,
  filename: string,
  replaceObject: object
) => {
  let currentHTML = html;

  if (replaceObject)
    Object.entries(replaceObject).forEach(
      ([key, value]) => (currentHTML = currentHTML.replace(`{{${key}}}`, value))
    );

  downloadFile(
    `${textURI}${encodeURIComponent(currentHTML)}`,
    `${filename ?? "file"}.html`
  );
};

export const listObjectToCSV = (
  list: object[],
  filename?: string,
  head?: string[]
) => {
  if (!list) return;

  const csv = list
    .map((item, index) => {
      let csv = "";
      if (index === 0) csv = `${(head ?? Object.keys(item)).join(";")}\n`;
      return csv + Object.values(item).join(";");
    })
    .join("\n");

  downloadCSV(csv, filename ?? "file");
};

export const listObjectToXLSX = (
  filename = "file",
  pages: PageXLSType | PageXLSType[]
) => {
  if (!pages) return;

  const wb = utils.book_new();

  const addPage = (page: PageXLSType) => {
    const ws = utils.json_to_sheet(page.list);

    if (page.head?.length) utils.sheet_add_aoa(ws, [page.head]);

    if (page.footer?.length)
      utils.sheet_add_aoa(ws, [page.footer], { origin: -1 });

    utils.book_append_sheet(wb, ws, page.name);
  };

  if (Array.isArray(pages)) pages.map((p) => addPage(p));
  else addPage(pages);

  writeFileXLSX(wb, `${filename}.xlsx`);
};

export const tableToCSV = (
  table: HTMLTableElement,
  filename?: string,
  removeFirst?: boolean,
  removeLast?: boolean
) => {
  if (!table) return;

  const rows = Array.from(table.querySelectorAll("tr"));

  const csv = rows
    .map((row) => {
      const cols = Array.from(row.querySelectorAll("th,td"));
      if (removeFirst) cols.shift();
      if (removeLast) cols.pop();
      return cols.map(({ textContent }) => textContent).join(";");
    })
    .join("\n");

  downloadCSV(csv, filename ?? "file");
};
