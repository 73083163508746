import { ss, tokenKey } from "consts";
import md5 from "md5";
import { BaseProviderType, PasswordStatusEnum } from "models";
import { useToast } from "providers/toast";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as UserService from "services/user";
import { aytyFormatError, clearLocalStorage } from "utils";
import { AuthErrors } from "./errors";

const invalidLogin = -99;

const { Expired, AskChangeAlmostExpiredPassword } = PasswordStatusEnum;

type AuthContextType = {
  token?: string;
  isChanging: boolean;
  isAuthenticated: boolean;
  signIn: (user: string, password: string) => Promise<void>;
  signOut: () => void;
  changePassword: () => void;
  cancelChange: () => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const initialToken = ss.getItem(tokenKey) as string | null;

export const AuthProvider = ({ children }: BaseProviderType) => {
  const [token, setToken] = useState<string>(initialToken ?? "");
  const [isChanging, setIsChanging] = useState(false);
  const { error, warning } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const errorsResolver = useMemo(
    () => new AuthErrors({ error, warning }, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isAuthenticated = useMemo(() => !!token, [token]);

  const saveData = useCallback((currentToken: string) => {
    setToken(currentToken);
    ss.setItem(tokenKey, currentToken);
  }, []);

  const deleteData = useCallback(() => {
    setToken("");
    clearLocalStorage();
  }, []);

  const signIn = useCallback(
    async (pDeLogin: string, pDePassword: string) => {
      await UserService.signIn({
        pDeLogin,
        pDePasswordHash: md5(pDePassword),
        pDeUrl: window.location.host,
      })
        .then(({ data }) => {
          if (data.passwordExpiredStatus === AskChangeAlmostExpiredPassword) {
            warning({ description: t("alerts.almostExpiredPassword") });
          }

          switch (true) {
            case data.passwordExpiredStatus === Expired:
              navigate(`authentication/new-password/${data.deToken}`);
              break;
            case data.idReturnAPI > 0:
              saveData(data.deToken);
              break;
            case data.idReturnAPI === invalidLogin:
              warning({ description: t("errors.invalidAccess") });
              break;
            default:
              warning({ description: t(aytyFormatError(data)) });
              break;
          }
        })
        .catch(errorsResolver.signIn);
    },
    [errorsResolver, navigate, saveData, warning, t]
  );

  const signOut = useCallback(() => {
    deleteData();
    setIsChanging(false);
    navigate("/authentication", { replace: true });
  }, [deleteData, navigate]);

  const changePassword = useCallback(() => {
    setIsChanging(true);
    navigate(`authentication/new-password/${token}`, { replace: true });
  }, [token, navigate]);

  const cancelChange = useCallback(() => {
    setIsChanging(false);
    navigate("/", { replace: true });
  }, [navigate]);

  return (
    <AuthContext.Provider
      value={{
        token,
        isChanging,
        isAuthenticated,
        signIn,
        signOut,
        changePassword,
        cancelChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
