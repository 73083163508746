import { AxiosRequestConfig } from "axios";
import { BarChartType } from "components/barChart";
import { PieChartType } from "components/pieChart";
import {
  EnvironmentEnum,
  OptionType,
  ReportEnum,
  SubChannelTypeEnum,
} from "models";

// Values
export const activeStatus = 1;

export const allStatus = 0;

export const aytyErrorLabel = "#AYTY_EXCEPTION:";

export const customerIsOut = -15;

export const customerEnded = -16;

export const developmentIdRole = 7;

export const documentationPath =
  "https://ligocloud.atlassian.net/wiki/spaces/DA/pages/2620489729/Ligo+CRM+Cloud";

export const emptyValue = "empty";

const { development, test } = EnvironmentEnum;

const environment = process.env.REACT_APP_ENVIRONMENT || development;

export const environmentIsDevelopment =
  environment === development || environment === test;

export const externalReportPath = "/externalReport";

export const i18nLanguage = "i18nextLng";

export const inactiveStatus = -1;

export const itemsPerPaginations: number[] = [4, 8, 12, 16, 20];

export const languages = ["pt", "en", "es"];

export const messageSubChannel = [
  SubChannelTypeEnum.Chat,
  SubChannelTypeEnum.ChatExternal,
  SubChannelTypeEnum.WhatsApp,
];

export const modalRoot = document.getElementById("modal");

export const newItem = -1;

export const notepadPath = "/notepad";

export const popupSize = { x: 600, y: 400 };

export const unauthorizedCode = -999;

export const unauthorizedToken = 419;

export const voiceSubChannel = [
  SubChannelTypeEnum.Inbound,
  SubChannelTypeEnum.Predictive,
  SubChannelTypeEnum.Preview,
];

// Objects
export const attendanceErrors = {
  alreadyInAttendance: -2,
  noVoiceAnswering: -3,
  invalidQueue: -4,
  unassociatedUser: -5,
  voiceClientExists: -6,
};

export const dashboradChartBarOptions: Omit<BarChartType, "data"> = {
  indexBy: "status",
  width: "100%",
  height: "24rem",
  padding: 0.3,
  animate: false,
  enableGridX: false,
  enableGridY: false,
  margin: { bottom: 58 },
  labelTextColor: "#FFFFFF",
  valueScale: { type: "linear" },
  legends: [
    {
      dataFrom: "indexes",
      anchor: "bottom-left",
      direction: "row",
      translateY: 58,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      symbolSize: 10,
      symbolShape: "circle",
    },
  ],
};

export const dashboardChartPieOptions: Omit<PieChartType, "data"> = {
  width: "100%",
  height: "24rem",
  animate: false,
  margin: { bottom: 58 },
  innerRadius: 0.5,
  enableArcLinkLabels: false,
  arcLabelsRadiusOffset: 1.3,
  arcLabelsTextColor: "var(--text)",
  legends: [
    {
      anchor: "bottom-left",
      direction: "row",
      translateY: 58,
      itemsSpacing: 2,
      itemWidth: 120,
      itemHeight: 20,
      itemDirection: "left-to-right",
      symbolSize: 10,
      symbolShape: "circle",
    },
  ],
};

export const formDataConfig: AxiosRequestConfig = {
  headers: { "Content-Type": "multipart/form-data" },
  transformRequest: (formData) => formData,
};

// Options
export const adminReportOptions: OptionType<ReportEnum>[] = [
  { label: "labels.overview", value: ReportEnum.overview },
  { label: "labels.licenseAnalytic", value: ReportEnum.licenseAnalytic },
  {
    label: "labels.licenseConsolidated",
    value: ReportEnum.licenseConsolidated,
  },
];

export const statusOptions: OptionType<
  typeof allStatus | typeof activeStatus | typeof inactiveStatus
>[] = [
  { value: allStatus, label: "labels.all" },
  { value: activeStatus, label: "labels.active" },
  { value: inactiveStatus, label: "labels.inactive" },
];

export const reportOptions: OptionType<ReportEnum>[] = [
  { label: "labels.interactions", value: ReportEnum.interaction },
  {
    label: "labels.interactionAndRecording",
    value: ReportEnum.interactionAndRecording,
  },
  { label: "labels.interactionMap", value: ReportEnum.interactionMap },
  {
    label: "labels.operationsPerformance",
    value: ReportEnum.operationsPerformance,
  },
  {
    label: "labels.detailedSales",
    value: ReportEnum.detailedSales,
  },
  {
    label: "labels.ticketAnalytic",
    value: ReportEnum.ticketAnalytic,
  },
  {
    label: "labels.ticketConsolidated",
    value: ReportEnum.ticketConsolidated,
  },
  { label: "labels.calls", value: ReportEnum.call },
  { label: "labels.orders", value: ReportEnum.order },
];
